import React from "react";
import Sidebar from "../../components/Sidebar/SideBar";
import View from './View';

const CustomImageLink = () => {
  return (
    <Sidebar>
      <div className="flex justify-center items-center">
        <View />
      </div>
    </Sidebar>
  );
};

export default CustomImageLink;
