import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { apiConnector } from '../../../services/apiConnector';
import { CustomImageLinkAPI } from '../../../services/apis';
import { Button } from '@chakra-ui/react'
import { AiOutlineRight, AiOutlineLeft } from 'react-icons/ai'
import Loader from '../../../components/Loader/Loader';
import { Link } from 'react-router-dom';
import moment from 'moment'
import Edit from './Edit'

const View = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const admin = useSelector((state) => state.admin);

  const getData = async () => {
    window.scroll(0, 0);
    setLoading(true);
    try {
        const response = await apiConnector({ 
            method: "GET", 
            url: CustomImageLinkAPI.fetchAllImageLinks, 
            headers: { token: admin.token } 
        });

        setData(response.data.data);
    } catch (error) {
        toast.error(error?.response?.data?.message);
    }
    setLoading(false);
};

  useEffect(() => {
    const getData = async () => {
    window.scroll(0, 0);
    setLoading(true);
    try {
        const response = await apiConnector({ 
            method: "GET", 
            url: CustomImageLinkAPI.fetchAllImageLinks, 
            headers: { token: admin.token } 
        });
        setData(response.data.data);
    } catch (error) {
        toast.error(error?.response?.data?.message);
    }
    setLoading(false);
};
 getData();
  }, [admin])

  const HandleDelete = async (id) => {
    try {
      const res = await apiConnector({ method: "DELETE", url: CustomImageLinkAPI.deleteImageLinkData + `/${id}`, headers: { token: admin.token } })
      if (res?.data?.success) {
        toast.success(res?.data?.message);
      }
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      }
    }
    getData();
  }

  return (
    <div className='w-full min-h-screen relative' >
      <h2 className='text-2xl font-[Poppins] text-center text-white font-[500] pb-4 tracking-wide'>
        CUSTOM IMAGE LINK
      </h2>

      {
        loading ?
          <>
            (<div className='w-full flex items-center justify-center mt-10'> <Loader width={"100"} height={"80"} /> </div>)
          </>
          :
          <div>
            {
              data?.length > 0 &&
              data?.map((item, index) => (

                <div key={index} className="flex justify-center items-center w-fit ">
              <div
                className="border-2 p-3 border-[#233c77] rounded-xl h-[25rem] sm:w-[60%] w-[100%] md:w-[80%] max-h-[400px] flex flex-col justify-center items-center overflow-hidden shadow-xl hover:shadow-none duration-200 group relative before:absolute before:h-[6px] before:bottom-0 before:bg-[#233c77] before:left-0 before:w-full before:transition-all before:duration-500 hover:before:h-[100%] before:-z-10 before:rounded-xl mt-5
                        "
              >
                <img
                  src={item?.image}
                  className="h-[18rem] xl:w-[auto] w-[auto] rounded-md"
                  alt=""
                />

                <Link
                  to={item?.link}
                  target="_blank"
                  className={`text-sm mt-2 font-[500]  mb-2 py-1 rounded-xl px-4 custom_colors border-2 hover:border-[#233c77]  hover:text-[#233c77] hover:bg-white transition-all duration-300 ease-in-out flex justify-center items-center  tracking-wide`}
                >
                  View
                </Link>

                <Edit item={item} />

                {/* <div className='flex justify-between items-center my-2'>
                      <Button colorScheme="red" size="xs" isDisabled={loading}
                        onClick={() => {
                          HandleDelete(item._id);
                        }}>
                        <p className='font-[Poppins] font-[400] flex justify-center items-center'>Delete</p>
                      </Button>
                    </div> */}
              </div>
            </div>
              ))
            }
          </div>
      }
    </div>
  )
}

export default View
